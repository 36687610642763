
import React, { useEffect, useState, useRef } from 'react';
import { FaPrint } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import KeyValue from '../ReusableFormiks/KeyValue';

import {
 
  finalAbilityToPay,
} from '../Common/modules/NewFiveCSums';

const Eval = () => {
  const { targetLoan, targetClient } = useSelector(store => store.systemLookups);
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  const dispatch = useDispatch();

  const handlePrint = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const data = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(targetClient.amDisplayName + '.pdf');
  };

  // Get today's date
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  useEffect(() => {
    console.log(targetLoan?.loan_fonance_items);

    const categoryMap = new Map();
    targetLoan?.loan_fonance_items?.forEach(item => {
      if (!categoryMap.has(item.category)) {
        categoryMap.set(item.category, []);
      }
      categoryMap.get(item.category).push(item);
    });

    const groupedData = Array.from(categoryMap, ([category, items]) => ({ category, items }));
    console.log(groupedData);
  }, []);

  if (isLoading) {
    return (
      <div className="max-h-fit flex items-center justify-center bg-gray-100">
        <div className="flex flex-col items-center justify-center">
          <PulseLoader color="orange" loading="true" />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div ref={componentRef} className="p-8 print-area">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-4xl font-bold text-blue-700">RATIOS Credit Analysis</div>
        <div className="text-xl font-bold ">
          <FaPrint onClick={handlePrint} size={25} />
        </div>
        <div className="text-4xl font-bold text-blue-700">Amigos SACCO</div>
      </div>

      {/* Credit Union Information */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <div className="font-semibold p-1">
              Name:
              <span className="font-normal p-1">{targetClient.amDisplayName}</span>
            </div>
            <div className="font-semibold p-1">
              Address:
              <span className="font-normal p-1">{`${targetClient.regionId}, ${targetClient.zoneId}, ${targetClient.woredaId}`}</span>
            </div>
            <div className="font-semibold p-1">
              Member Since:
              <span className="font-normal p-1">{targetClient.memberSince}</span>
            </div>
            <div className="font-semibold p-1">
              Today's Date:
              <span className="font-normal p-1"> {formattedDate} </span>
            </div>
            <div className="font-semibold p-1">
              Employer: <span className="font-normal p-1"> ----- </span>
            </div>
          </div>
          <div>
            <div className="font-semibold p-1">
              Account #:
              <span className="font-normal p-1">{` ${targetClient.entityAccountNo} `}</span>
            </div>
            <div className="font-semibold p-1">
              Term (months):
              <span className="font-normal p-1">{` ${targetLoan.numberOfRepayments} Months`}</span>
            </div>
            <div className="font-semibold p-1">
              Interegst Rate:
              <span className="font-normal p-1">{` ${targetLoan.annualInterestRate} %`}</span>
            </div>
            <div className="font-semibold p-1">
              Work Telephone:
              <span className="font-normal p-1">{` ${targetClient.mobileNo}`}</span>
            </div>
            <div className="font-semibold p-1">
              Monthly Payment:
              <span className="font-normal p-1">{` ${targetLoan.r_totalDueForPeriod}`}</span>
            </div>
            <div className="font-semibold p-1">
              Position: <span className="font-normal p-1"> ---- </span>
            </div>
          </div>
          <div>
            <div className="font-semibold p-1">
              External Acount:
              <span className="font-normal p-1">{` ${targetClient.entityExternalId}`}</span>
            </div>
            <div className="font-semibold p-1">
              Use: <span className="font-normal p-1"> --120-- </span>
            </div>
            <div className="font-semibold p-1">
              Type : <span className="font-normal p-1">--0.1%--</span>
            </div>
            <div className="font-semibold p-1">
              Productive (G), provident(I) :<span className="font-normal p-1"> ---- </span>
            </div>
            <div className="font-semibold p-1">
              How Long : <span className="font-normal p-1">----</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2 h-full">
        <div className="grid grid-rows-none gap-2 w-full h-fit">
          {/* C1 Character */}
          <div className="col-span-1 bg-white rounded-lg shadow-md h-fit">
            <div className="font-bold bg-orange-400 p-3">C1 CHARACTER (58.0%)</div>
            <div className=" p-3">
              <div className="font-semibold p-1 ">A. ID & Marriage Status or Residence</div>
              <div className="ml-3">
                <KeyValue thekey="Married with registered paper" value="" isodd="True" />
                <KeyValue thekey="Time in current residence or In AA (in months)" value="" isodd="False" />
              </div>

              <div className=" font-semibold p-1">B. Work/Business Ownership</div>
              <div className="ml-3">
                <KeyValue thekey="Self-Owned(S), Partnership(P), Complicated(C)" value="" isodd="True" />

                <KeyValue thekey="Time at previous Business (in months), with attachment" value="" isodd="False" />
              </div>

              <div className=" font-semibold p-1">C. Credit and Saving Experience</div>
              <div className="ml-3">
                <KeyValue thekey="Good history in last loan or present loan Yes (Y), No (N)" value="" isodd="True" />
                <KeyValue thekey="Saving descipline, Platinium(P), Gold(G), Silver(S)" value="" isodd="False" />
                <KeyValue thekey="Reference in Amigos Good(G), Average(A), Poor(P)" value="" isodd="True" />
              </div>
            </div>
          </div>

          {/* C2 Capital */}
          <div className="col-span-1 bg-white rounded-lg shadow-md h-fit">
            <div className="font-bold bg-orange-400 p-3 rounded-md">C2 CAPITAL (80.0%)</div>
            <div className="p-3">
              <div className="font-semibold p-1">A. Total Assets</div>

              <div className="ml-3">
                <KeyValue
                  thekey="Cash and Cash Equivalents (in banks or related institution)"
                  value={targetLoan?.loan_fonance_items[25]?.markValue}
                  isodd="True"
                />
                <KeyValue
                  thekey="Account Receivable"
                  value={targetLoan?.loan_fonance_items[26]?.markValue}
                  isodd="False"
                />
                <KeyValue thekey="Inventory" value={targetLoan?.loan_fonance_items[27]?.markValue} isodd="True" />
                <KeyValue
                  thekey="Propert, Plant and Equivalent (Fixed and mobile/movable)"
                  value={targetLoan?.loan_fonance_items[28]?.markValue}
                  isodd="False"
                />
              </div>

              <div className="font-semibold p-1">B. Liabilities</div>
              <div className="ml-3">
                <KeyValue thekey="Account Payable" value={targetLoan?.loan_fonance_items[29]?.markValue} isodd="True" />
                <KeyValue
                  thekey="Short-Term Loans"
                  value={targetLoan?.loan_fonance_items[30]?.markValue}
                  isodd="False"
                />
                <KeyValue thekey="Long-Term Loans" value={targetLoan?.loan_fonance_items[31]?.markValue} isodd="True" />
              </div>

              <div className=" font-semibold p-1">C. Net Capital</div>
              <div className="ml-3">
                <KeyValue
                  thekey="Shares or other related bounds"
                  value={targetLoan?.loan_fonance_items[32]?.markValue}
                  isodd="True"
                />
                <KeyValue
                  thekey="Retailed earnings"
                  value={targetLoan?.loan_fonance_items[33]?.markValue}
                  isodd="False"
                />
              </div>
              {/* total  */}
              <div className="flex bg-orange-200 rounded-md px-3 py-2 justify-between">
                <div>Total</div>
                <div>
                  {/* {Capital_A(targetLoan.loan_fonance_items) +
                    Capital_B(targetLoan.loan_fonance_items) +
                    Capital_C(targetLoan.loan_fonance_items)} */}
                </div>
              </div>
            </div>
          </div>

          {/* C5 Collateral */}
          <div className="col-span-1 bg-white rounded-lg shadow-md  h-fit">
            <div className="font-bold bg-orange-400 p-3 rounded-md">C5 COLLATERAL (100%)</div>
            <div className=" p-3">
              <div className="font-semibold p-1">A. Personal Guaranty</div>
              <div className="ml-3">
                <KeyValue thekey="First guarantor" value="" isodd="True" />
                <KeyValue thekey="Second guarantor" value="" isodd="False" />
              </div>
              <div className=" font-semibold p-1">B. Collateral Guaranty</div>
              <div className="ml-3">
                <KeyValue thekey="Location: Urban Sector(U), Rural Sector(R)" value="" isodd="True" />
                <KeyValue thekey="Quality of collateral: High(H), Medium(M), Low(L), Null(N)" value="" isodd="False" />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-rows-none gap-2 w-full h-fit">
          {/* C3 Ability to Pay */}
          <div className="col-span-1 bg-white rounded-lg shadow-md h-fit">
            <div className="font-bold bg-orange-400 p-3 rounded-md">C3 ABILITY TO PAY (21.2%)</div>

            <div className=" p-3">
              <div className="font-semibold p-1">A. Monthly Income</div>
              <div className="ml-3">
                <KeyValue
                  thekey="From Product/Service sale's"
                  value={targetLoan?.loan_fonance_items[18]?.markValue}
                  isodd="False"
                />

                <KeyValue thekey="Commissions" value={targetLoan?.loan_fonance_items[19]?.markValue} isodd="True" />
                <KeyValue
                  thekey="Professional fees or salary"
                  value={targetLoan?.loan_fonance_items[20]?.markValue}
                  isodd="False"
                />
                <KeyValue
                  thekey="Profit from interest on investments"
                  value={targetLoan?.loan_fonance_items[21]?.markValue}
                  isodd="True"
                />
                <KeyValue
                  thekey="Rent from housing or commercial locale"
                  value={targetLoan?.loan_fonance_items[22]?.markValue}
                  isodd="False"
                />
                <KeyValue
                  thekey="Grants and Funding"
                  value={targetLoan?.loan_fonance_items[23]?.markValue}
                  isodd="True"
                />
                <KeyValue
                  thekey="Interest Income"
                  value={targetLoan?.loan_fonance_items[24]?.markValue}
                  isodd="False"
                />

                {/* total  */}
                <div className="flex bg-orange-200 rounded-md px-3 py-2 justify-between">
                  <div>Total</div>
                  <div>{ finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) }</div>
                </div>
              </div>
            </div>

            <div className=" p-3">
              <div className=" font-semibold p-1">B. Monthly Expenditures</div>
              <div className="ml-2">
                <div className=" font-semibold p-1">B.1. Basic</div>
                <div className="ml-3">
                  <KeyValue
                    thekey="Cost of Goods Sold (COGS)"
                    value={
                      targetLoan?.loan_fonance_items[0]?.markValue +
                      targetLoan?.loan_fonance_items[1]?.markValue +
                      targetLoan?.loan_fonance_items[2]?.markValue
                    }
                    isodd="True"
                  />
                  <KeyValue
                    thekey="Operating Expenses"
                    value={
                      targetLoan?.loan_fonance_items[3]?.markValue +
                      targetLoan?.loan_fonance_items[4]?.markValue +
                      targetLoan?.loan_fonance_items[5]?.markValue +
                      targetLoan?.loan_fonance_items[6]?.markValue +
                      targetLoan?.loan_fonance_items[7]?.markValue +
                      targetLoan?.loan_fonance_items[8]?.markValue +
                      targetLoan?.loan_fonance_items[9]?.markValue +
                      targetLoan?.loan_fonance_items[10]?.markValue
                    }
                    isodd="False"
                  />
                  <KeyValue
                    thekey="Interest Expenses"
                    value={
                      targetLoan?.loan_fonance_items[11]?.markValue + targetLoan?.loan_fonance_items[12]?.markValue
                    }
                    isodd="True"
                  />
                  <KeyValue
                    thekey="Taxes Related Expenses"
                    value={targetLoan?.loan_fonance_items[13]?.markValue}
                    isodd="False"
                  />
                  <KeyValue
                    thekey="Personal Expenses"
                    value={targetLoan?.loan_fonance_items[25]?.markValue}
                    isodd="True"
                  />
                  <KeyValue
                    thekey="Maintenance & related"
                    value={targetLoan?.loan_fonance_items[25]?.markValue}
                    isodd="False"
                  />
                </div>
              </div>
              <div className="ml-2">
                <div className=" font-semibold p-1">B.1. Financial</div>
                <div className="ml-3">
                  <KeyValue thekey="Credit Union-Banks" value={targetLoan.ex_creditunionbanks} isodd="True" />
                  <KeyValue thekey="Credit Cards" value={targetLoan.ex_creditcards} isodd="False" />
                  <KeyValue thekey="Initial Investment" value={targetLoan.ex_initialinvestment} isodd="True" />
                  <KeyValue thekey="Sum of cash flow" value={targetLoan.ex_sumofcashflow} isodd="False" />
                </div>
              </div>
              <div className="ml-2">
                <div className="font-semibold p-1">B.1. Others</div>
                <div className="ml-3">
                  <KeyValue thekey="Housing related income" value={targetLoan.ex_housingandrelated} isodd="True" />
                  <KeyValue thekey="Vehicle Fuel" value={targetLoan.ex_vehiclefuel} isodd="False" />
                  <KeyValue thekey="Other Expenses" value={targetLoan.ex_other} isodd="True" />
                </div>
              </div>
              {/* total  */}
              <div className="flex bg-orange-200 rounded-md px-3 py-2 justify-between">
                <div>Total</div>
                <div>{ finalAbilityToPay(targetLoan, targetLoan?.loan_fonance_items) }</div>
              </div>
            </div>
          </div>

          {/* C4 Conditions */}
          <div className="col-span-1 bg-white rounded-lg shadow-md h-fit">
            <div className="font-bold bg-orange-400 p-3 rounded-md">C4 CONDITIONS (76.7%)</div>
            <div className=" p-3">
              <div className="font-semibold p-1">A. Main Source of Income</div>
              <div className="ml-3">
                <KeyValue thekey="Business location Around AA(rAA) in AA(AA)" value="" isodd="True" />
                <KeyValue thekey="Agent out of AA: YES(Y), No(N)" value="" isodd="False" />
                <KeyValue thekey="Office Setup: Excellent(E), Average(A), Poor(P)" value="" isodd="True" />
                <KeyValue thekey="Staff Condition: Saturated(S), Growing(G)" value="" isodd="False" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full gap-2">
        <div className="w-1/2">
          <div className="mt-2 bg-white p-4 rounded-lg shadow-md">
            <div className="font-semibold p-1">
              Business Revenue & Profit evaluation (creditworthiness of z business)
            </div>
            <KeyValue
              thekey="Debt to income ratio"
              value={`${targetLoan.in_frompruduct}  -- below 45% is acceptable`}
              isodd="False"
            />
            <KeyValue
              thekey="Debt service coverage ratio"
              value={`${targetLoan.in_frompruduct}  -- DSCR >= 1 is acceptable`}
              isodd="False"
            />
            <KeyValue
              thekey="Internal Rate of return"
              value={`${targetLoan.in_frompruduct}  -- >= 20% is acceptable`}
              isodd="False"
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="mt-2 bg-white p-4 rounded-lg shadow-md">
            <KeyValue thekey="DIR" value={`1%`} isodd="False" />
            <KeyValue thekey="DSCR" value={`609%`} isodd="False" />
            <KeyValue thekey="IRR" value={`8%`} isodd="False" />
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="mt-2 bg-white p-4 rounded-lg shadow-md">
        <div className="flex justify-between">
          <div>
            <div className="font-semibold p-1">
              TOTAL SCORE: <span className="font-normal p-1">67.2%</span>
            </div>
            <div className="font-semibold p-1">
              LEVEL OF RISK: <span className="font-normal p-1">POTENTIAL</span>
            </div>
          </div>
          <div>
            <div className="font-semibold p-1">Signed: ____________________</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eval