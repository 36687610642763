import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ReportStyle from '../Styles/ReportStyle';

function TextCenter(props) {
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                textAlign: 'center'
            }}
        >
            <Text style={ReportStyle.textCreditRatio}> {props.thekey} </Text>
            <Text style={ReportStyle.textCreditRatio}> {props.value} </Text>
        </View>
    );
}

export default TextCenter