import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import KeyValue from './Reusables/KeyValue';

import ReportStyle from './Styles/ReportStyle';
import { CapitalEvaluation, Debt_To_Asset_Ratio, finalAbilityToPay, LiquidityRatio, Loan_To_Value_Ratio, Monthly_Basic_Expenses, NetWorth, Sum_Cogs, Sum_Interest_Expenses, Sum_Operating_Expenses, Sum_Personal_Expenses, Sum_Tax_Expenses } from '../Common/modules/NewFiveCSums';
import TextCenter from './Reusables/TextCenter';
import { fifthC, firstC } from '../Common/modules/FiveCChecker';


const Styles = StyleSheet.create({
    heading_styles: {
        backgroundColor: '#ffa500',
        padding: 5,
        fontWeight: 'bold',
        color: '#fff',
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 8,
    },
    subheading_styles: {
        backgroundColor: '#FFDAB9',
        padding: 5,
        fontWeight: 'bold',
        marginBottom: 2,
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 8,
        padding: 3,
        color: '#000',
        fontFamily: 'AmharicFont',
    }
});

const RatiosCreditAnalysis = ({ customer, loan, committee, creditRatioData, formattedDate, conditionData }) => (

    <PDFViewer style={{ width: '100%', height: 1300 }}>
        <Document>
            <Page size="A4" style={ReportStyle.page}>
                {/* Header */}
                <View style={ReportStyle.header}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold' }}>RATIOS Credit Analysis</Text>
                    <Text>Amigos SACCO</Text>
                </View>

                {/* Basic Info */}
                <View style={ReportStyle.parentrow}>
                    {/* First Column */}
                    <View style={ReportStyle.childcolumn}>
                        <Text style={ReportStyle.text}>Name: {customer.amDisplayName}</Text>
                        <Text style={ReportStyle.text}>Address: {`${customer.regionId}, ${customer.zoneId}, ${customer.woredaId}`} </Text>
                        <Text style={ReportStyle.text}>Member Since: {customer.memberSince}</Text>
                        <Text style={ReportStyle.text}>Today's Date: {formattedDate}  </Text>
                        <Text style={ReportStyle.text}>Employer: ---- </Text>
                    </View>
                    {/* Second Column */}
                    <View style={[ReportStyle.childcolumn, { marginHorizontal: 5 }]}>
                        <Text style={ReportStyle.text}>Account #: {customer.entityAccountNo} </Text>
                        <Text style={ReportStyle.text}>Term (months): {` ${loan.numberOfRepayments} Months`} </Text>
                        <Text style={ReportStyle.text}>Interegst Rate: {` ${loan.annualInterestRate} %`} </Text>
                        <Text style={ReportStyle.text}>Work Telephone: {` ${customer.mobileNo}`} </Text>
                        <Text style={ReportStyle.text}>Monthly Payment: {` ${loan.r_totalDueForPeriod}`} </Text>
                        <Text style={ReportStyle.text}>Position: ------ </Text>
                    </View>
                    {/* Third Column */}
                    <View style={ReportStyle.childcolumn}>
                        <Text style={ReportStyle.text}>External Acount: {` ${customer.entityExternalId}`} </Text>
                        <Text style={ReportStyle.text}>Use: ----120 ---- </Text>
                        <Text style={ReportStyle.text}>Type : ----- </Text>
                        <Text style={ReportStyle.text}>Productive (G), provident(I) : ---- </Text>
                        <Text style={ReportStyle.text}>How Long: ---- </Text>
                    </View>
                </View>


                <View style={ReportStyle.parentrow}>
                    <View style={ReportStyle.childcolumn}>
                        {/* CHARACTER */}
                        <View style={Styles.heading_styles}>
                            <Text >C1 CHARACTER </Text>
                            <Text > (58.0%)</Text>
                        </View>

                        <View style={{ paddingLeft: 5 }}>
                            <Text style={ReportStyle.sectionSubHeader}>WORK/BUSINESS OWNERSHIP</Text>
                            <KeyValue bgcolor="#D3D3D3" thekey="Businees Owned by" value={loan?.businessOwnership?.amName} />
                            <KeyValue bgcolor="white" thekey="Time at previous Business (in months);with attachment" value={loan?.previousBusinessTenureInMonths} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Time in current residence or In AA (in months)" value={loan?.tenureInMonths} />
                            <Text style={ReportStyle.sectionSubHeader}>CREDIT AND SAVING EXPERIENCE</Text>
                            <KeyValue bgcolor="#D3D3D3" thekey="Good history in last loan or present loan" value={loan?.lastLoanGoodHistory ? "YES" : "NO"} />
                            <KeyValue bgcolor="white" thekey="Saving despline" value={loan?.savingdecipline?.amName} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Reference In Amigos" value={loan?.referenceinamigos?.amName} />
                        </View>
                        <View style={{ marginTop: 10 }}></View>
                        {/* Capital Start  */}

                        <View style={Styles.heading_styles}>
                            <Text > C2 CAPITAL </Text>
                            <Text >  {CapitalEvaluation(loan) + " %"} </Text>
                        </View>

                        <View style={{ paddingLeft: 5 }}>
                            <Text style={ReportStyle.sectionSubHeader}>1. TOTAL ASSETS
                                {/* {Sum_Assets(creditRatioData)} */}
                            </Text>
                            <KeyValue bgcolor="#D3D3D3" thekey="Cash and Cash Equivalents (in bank or related institution)" value={creditRatioData[26]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Account Receivable" value={creditRatioData[27]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Inventory" value={creditRatioData[28]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Property, Plant, and Equipment:(fixed and mobile/ movable)" value={creditRatioData[29]?.markValue} />

                            <Text style={ReportStyle.sectionSubHeader}>2. LIABILITIES</Text>
                            <KeyValue bgcolor="#D3D3D3" thekey="Accounts Payable" value={creditRatioData[30]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Short-Term Loans" value={creditRatioData[31]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Long-Term Loans" value={creditRatioData[32]?.markValue} />

                            <Text style={ReportStyle.sectionSubHeader}>3. CAPITAL</Text>
                            <KeyValue bgcolor="#D3D3D3" thekey="Shares or other related bounds" value={creditRatioData[33]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Retailed earnings" value={creditRatioData[34]?.markValue} />
                        </View>
                        {/* Capital End  */}

                    </View>
                    <View style={ReportStyle.childcolumn}>
                        <View style={Styles.heading_styles}>
                            <Text > C3 ABILITY TO PAY </Text>
                            <Text >
                                {finalAbilityToPay(loan, creditRatioData)}
                                {/* {finalAbilityToPay(loan, creditRatioData) > 100 ? "100 %" : finalAbilityToPay(loan, creditRatioData) / 4 + " %"} */}
                            </Text>
                        </View>
                        <View>
                            <Text style={ReportStyle.sectionSubHeader}>1. MONTHLY INCOME</Text>
                            <KeyValue bgcolor="#D3D3D3" thekey="From product/service sale's" value={creditRatioData[18]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Commissions" value={creditRatioData[19]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Professional Fees or salary" value={creditRatioData[20]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Profit from interest on investment" value={creditRatioData[21]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Rent from housing or commercial locale" value={creditRatioData[22]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Grants and Funding" value={creditRatioData[23]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Interest Income" value={creditRatioData[24]?.markValue} />


                            <Text style={ReportStyle.sectionSubHeader}>2. MONTHLY EXPENSES {Monthly_Basic_Expenses(creditRatioData)}</Text>
                            <View style={{ paddingLeft: 5 }}>
                                <Text style={ReportStyle.sectionSubHeader}>2.1. BASIC</Text>
                                <KeyValue bgcolor="#D3D3D3" thekey="Cost of Goods Sold (COGS)" value={Sum_Cogs(creditRatioData)} />
                                <KeyValue bgcolor="#FFF" thekey="Operating Expenses" value={Sum_Operating_Expenses(creditRatioData)} />
                                <KeyValue bgcolor="#D3D3D3" thekey="Interest Expenses" value={Sum_Interest_Expenses(creditRatioData)} />
                                <KeyValue bgcolor="#FFF" thekey="Taxes Related Expenses" value={Sum_Tax_Expenses(creditRatioData)} />
                                <KeyValue bgcolor="#D3D3D3" thekey="Personal expenses" value={Sum_Personal_Expenses(creditRatioData)} />

                                <KeyValue bgcolor="#FFF" thekey="Maintenance & related" value={creditRatioData[37]?.markValue} />
                                <Text style={ReportStyle.sectionSubHeader}>2.2. FINANCIAL</Text>
                                <KeyValue bgcolor="#D3D3D3" thekey="Credit Union-Banks" value={creditRatioData[38]?.markValue} />
                                <KeyValue bgcolor="#FFF" thekey="Credit Cards" value={creditRatioData[39]?.markValue} />
                                <KeyValue bgcolor="#D3D3D3" thekey="Initial investment" value={creditRatioData[35]?.markValue} />
                                <KeyValue bgcolor="#FFF" thekey="Sum of cash inflow" value={creditRatioData[36]?.markValue} />

                                <Text style={ReportStyle.sectionSubHeader}>2.3. OTHER</Text>
                                <KeyValue bgcolor="#D3D3D3" thekey="Housing Related to cleaning" value={creditRatioData[40]?.markValue} />
                                <KeyValue bgcolor="#FFF" thekey="Vehicle fuel" value={creditRatioData[41]?.markValue} />
                                <KeyValue bgcolor="#D3D3D3" thekey="Other Expenses" value={creditRatioData[42]?.markValue} />
                            </View>

                        </View>
                    </View>
                </View>

                <View style={ReportStyle.parentrow}>
                    <View style={ReportStyle.childcolumn}>
                        
                        <View style={Styles.heading_styles}>
                            <Text> C5. COLLATERAL (100%)</Text>
                            <Text> {(fifthC(loan) * 100) / 25 + " %"}  </Text>
                        </View>

                        <KeyValue bgcolor="#FFF" thekey="የተያዘው ንብረት ጠቅላላ ግምት" value={creditRatioData[31]?.markValue} />
                        <KeyValue bgcolor="#D3D3D3" thekey="የተፈቀደው የብድር መጠን" value={creditRatioData[30]?.markValue} />
                        <KeyValue bgcolor="#FFF" thekey="የተያዠው ንብረት ለሚሰጠው ጠቅላላ ብድር (RATIO)" value={creditRatioData[31]?.markValue} />
                    </View>
                    <View style={ReportStyle.childcolumn}>
                      
                        <View style={Styles.heading_styles}>
                            <Text> C4. CONDITIONS </Text>
                            <Text> {firstC(customer, loan) * 100 / 35 + " %"}  </Text>
                        </View>
                        {conditionData?.length > 0 && conditionData.map((data, index) => {
                            return <KeyValue bgcolor={index % 2 ? "#FFF" : "#D3D3D3"} thekey={data.amDesc} value={data.isUploaded ? data.markValue : 0} />
                        })}
                        {typeof (conditionData)}
                    </View>
                </View>


                {/* analysis both ability to pay and Capital */}
                <View style={ReportStyle.parentrow}>
                    <View style={ReportStyle.childcolumn}>
                        <Text style={ReportStyle.evalHeader}>Capital evaluation</Text>
                        <KeyValue bgcolor="#FFF" thekey="Liquidity Ratio" value={LiquidityRatio(creditRatioData)} />
                        <KeyValue bgcolor="#FFF" thekey="" value={LiquidityRatio(creditRatioData) > 1 ? "10 %" : "5%"} />
                        <KeyValue bgcolor="#FFF" thekey="Net Worth" value={NetWorth(creditRatioData)} />
                        <KeyValue bgcolor="#FFF" thekey="" value={NetWorth(creditRatioData) > 0 ? "10%" : "0%"} />
                        <KeyValue bgcolor="#FFF" thekey="Debt-to-Asset Ratio" value={Debt_To_Asset_Ratio(creditRatioData)} />
                        <KeyValue bgcolor="#FFF" thekey="" value={Debt_To_Asset_Ratio(creditRatioData) < 0.5 ? "30%" : "10%"} />

                        <KeyValue bgcolor="#FFF" thekey="Loan-to-value Ratio (LTV)" value={Loan_To_Value_Ratio(creditRatioData)} />
                        <KeyValue bgcolor="#FFF" thekey="" value={Loan_To_Value_Ratio(creditRatioData) < 0.8 ? "50%" : "20%"} />
                        {/* <Text>  {CapitalEvaluation(loan) + " %"} </Text> */}


                    </View>
                    <View style={ReportStyle.childcolumn}>
                        <Text style={ReportStyle.evalHeader}>Business Revenue & Profit Evaluation </Text>
                        <View style={{ paddingLeft: 5 }}>
                            <KeyValue bgcolor="#D3D3D3" thekey="Business Gross Income" value={creditRatioData[30]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Total Expenses" value={creditRatioData[31]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Total Expenses" value={creditRatioData[30]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Net Profit" value={creditRatioData[31]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Debt to income ratio" value={creditRatioData[30]?.markValue} />
                            <KeyValue bgcolor="#FFF" thekey="Debt-service coverage Ratio" value={creditRatioData[31]?.markValue} />
                            <KeyValue bgcolor="#D3D3D3" thekey="Internal Rate of return" value={creditRatioData[30]?.markValue} />
                            <TextCenter thekey="DIR = " value="hhhh" />
                            <TextCenter thekey="DSCR = " value="hhhh" />
                            <TextCenter thekey="IRR = " value={(10 * calculateIRR(creditRatioData[35]?.markValue, creditRatioData[36]?.markValue))?.toFixed(2)} />
                            <TextCenter thekey="ccccc = " value="ccccc" />
                        </View>
                    </View>
                </View>


                {/* Footer */}
                <View style={ReportStyle.parentrow}>
                    <View style={ReportStyle.childcolumn}>
                        <Text>Total Score: 67.2%</Text>
                        <Text>Level of Risk: Potential</Text>
                    </View>
                    <View style={ReportStyle.childcolumn}>
                    </View>
                </View>
            </Page>
        </Document>
    </PDFViewer >
);


export default RatiosCreditAnalysis