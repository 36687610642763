import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './Common/redux/Store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import NotFound from './Common/NotFound';
import Home from './Common/Home';
import ProtectedRoute from './Common/ProtectedRoute';

// Officer
import OfficerOnProgressLoans from './Role_Officer/OfficerOnProgressLoans';
import OfficerFinishedloans from './Role_Officer/OfficerFinishedloans';
import LoanOnOfficer from './Role_Officer/LoanOnOfficer';
import CarCollateralFiles from './Collaterals/CarCollateralFiles';

// Auditor and Team Leader
import LoanEvaluation from './Role_Auditor/LoanEvaluation';
import Eval from './Role_Auditor/Eval';
import NewLoanEvaluation from './Role_Auditor/NewLoanEvaluation';
import JudgeLoan from './Role_TeamLeader/JudgeLoan';
import JudgeLoanAuditor from './Role_Auditor/JudgeLoanAuditor';

// Admin - Lookup
import CarManufactureYear from './Role_Admin/Lookups/CarManufactureYear';
import CarModel from './Role_Admin/Lookups/CarModel';
import Holidays from './Role_Admin/Lookups/Holidays';
import HomeType from './Role_Admin/Lookups/HomeType';
import LoanType from './Role_Admin/Lookups/LoanType';
import LoanCommittee from './Role_Admin/LoanCommittee';
import JobPosition from './Role_Admin/JobPosition';
import Loan from './Role_Admin/Loan';

import Employees from './Role_Admin/Employees';

// Admin - DDL
import Banks from './Role_Admin/DDL/Banks';
import Cars from './Role_Admin/DDL/Cars';
import CompanyType from './Role_Admin/DDL/CompanyType';
import Country from './Role_Admin/DDL/Country';
import Delegation from './Role_Admin/DDL/Delegation';
import House from './Role_Admin/DDL/House';
import Membership from './Role_Admin/DDL/Membership';
import Salary from './Role_Admin/DDL/Salary';
import Share from './Role_Admin/DDL/Share';
import Single from './Role_Admin/DDL/Single';
import Singlegp from './Role_Admin/DDL/Singlegp';
import SingleSurety from './Role_Admin/DDL/SingleSurety';
import Married from './Role_Admin/DDL/Married';
import Marriedgp from './Role_Admin/DDL/Marriedgp';
import MarriedSurety from './Role_Admin/DDL/MarriedSurety';

// General
import PatchIP from './Common/PatchIP';

// Reports
import RequestFormDoc from './Reports/RequestFormDoc';
import Reports from './Reports/Reports';
import ChangePass from './Common/ChangePass';
import Profile from './Common/Profile';
import RatiosCreditAnalysis from './Reports/RatiosCreditAnalysis'

// Managemet Reports
import RptHistoryLoan from './Reports/RoleAdmin/RptHistoryLoan';
import RptHistoryWithdraw from './Reports/RoleAdmin/RptHistoryWithdraw';
import RptHistoryDisburse from './Reports/RoleAdmin/RptHistoryDisburse';
import RptPlansLoan from './Reports/RoleAdmin/RptPlansLoan';
import RptPlanWithdraw from './Reports/RoleAdmin/RptPlanWithdraw';
import RptPlanDisburse from './Reports/RoleAdmin/RptPlanDisburse';

// Planner
import Withdraw from './Role_Planner/Withdraw';
import DisburseNew from './Role_Planner/DisburseNew';
import DisburseApplication from './Role_Planner/DisburseApplication';
import WithdrawTabs from './Role_Planner/Tabs/WithdrawTabs';

import WithdrawRow from './Role_Planner/WithdrawRow';
import WithdrawRowDetail from './Role_Planner/WithdrawRowDetail';
import EvaluatorChecking from './Role_Evaluator/EvaluatorChecking';
import ManagerChecking from './Role_Manager/ManagerChecking';
import AuditorChecking from './Role_Auditor/AuditorChecking';
import AuditorWithdrawHome from './Role_Auditor/AuditorWithdrawHome';
import FinanceWithdrawChecking from './Role_Finance/FinanceWithdrawChecking';
import RptWithdraw from './Reports/RptWithdraw';
import RptDisbursement from './Reports/RptDisbursement';

// import AuditorChecking from "./Role_Auditor/EvaluatorChecking";

// Help
import HelpOfficer from './Role_Officer/HelpOfficer';
import FinanceHelp from './Role_Finance/FinanceHelp';
import TeamLeaderHelp from './Role_TeamLeader/TeamLeaderHelp';
import FinishedLoans from './FinishedLoans';
import TeamLeaderFinishedLoans from './Role_TeamLeader/TeamLeaderFinishedLoans';
import PlannerHelp from './Role_Planner/PlannerHelp';
import AuditorFinishedLoans from './Role_Auditor/AuditorFinishedLoans';
import DisburseFinanceChecking from './Role_Finance/DisburseFinanceChecking';
import NearDisburseDate from './Role_Finance/NearDisburseDate';
import NearWithdrawDate from './Role_Finance/NearWithdrawDate';

// import NewCarCollateralFiles from "./Tabs/NewCarCollateralFiles";

import WithdrawTest from './Common/Tests/WithdrawTest';
import Regions from './Role_Admin/Address/Regions';
import Zones from './Role_Admin/Address/Zones';
import Woredas from './Role_Admin/Address/Woredas';

// Bank Transfers
import NewTransfers from './Role_Admin/BankTransfers/NewTransfers';
import SyncData from './Role_Admin/BankTransfers/SyncData';
import Prohibitions from './Reports/Prohibitions';
import Contracts from './Reports/Contracts';
import TgProhibitions from './Reports/TgProhibitions';
import TgContracts from './Reports/TgContracts';
import NewDesign from './Common/NewDesign';
import CreditAnalysis from './Common/CreditAnalysis';
import LoanApplications from './Selfservice/LoanApplications';
import LoanApplicationFollowup from './Selfservice/LoanApplicationFollowup';

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={'/'}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Login />} />
          <Route path="/patchip" element={<PatchIP />} />

          <Route path="/finishedloans" element={<FinishedLoans />} />
          <Route path="/newdesign" element={<NewDesign />} />

          <Route path="/" element={<App />}>
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />

            {/* Officer  */}
            <Route path="/loanonofficer/:customerId/:loanId" element={<LoanOnOfficer />} />
            <Route path="/oonprogressloans" element={<OfficerOnProgressLoans />} />
            <Route path="/ofinished" element={<OfficerFinishedloans />} />
            <Route path="/carcollateralfiles/:loanId/:carId" element={<CarCollateralFiles />} />

            {/* Auditor and team leader */}
            <Route path="/loanevaluation/:customerId/:loanId" element={<LoanEvaluation />} />

            <Route path="/newloanevaluation/:customerId/:loanId" element={<NewLoanEvaluation />} />
            <Route path="/eval/:customerId/:loanId" element={<Eval />} />

            <Route path="/judgement/:customerId/:loanId" element={<JudgeLoan />} />
            <Route path="/judgeloanauditor/:customerId/:loanId" element={<JudgeLoanAuditor />} />

            <Route path="/auditorwithdrawhome" element={<AuditorWithdrawHome />} />

            <Route path="/afinishedloans" element={<AuditorFinishedLoans />} />

            {/* Admin Lookup */}
            <Route path="/carmanufactureyear" element={<CarManufactureYear />} />
            <Route path="/carmodel" element={<CarModel />} />
            <Route path="/holidays" element={<Holidays />} />
            <Route path="/hometype" element={<HomeType />} />
            <Route path="/loantype" element={<LoanType />} />

            <Route path="/loancommittee" element={<LoanCommittee />} />
            <Route path="/jobposition" element={<JobPosition />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/loan" element={<Loan />} />

            {/* Admin DDL */}
            <Route path="/banks" element={<Banks />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/companytype" element={<CompanyType />} />
            <Route path="/country" element={<Country />} />
            <Route path="/delegation" element={<Delegation />} />
            <Route path="/house" element={<House />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/salary" element={<Salary />} />
            <Route path="/share" element={<Share />} />

            <Route path="/single" element={<Single />} />
            <Route path="/singlegp" element={<Singlegp />} />
            <Route path="/singlesurety" element={<SingleSurety />} />
            <Route path="/married" element={<Married />} />
            <Route path="/marriedgp" element={<Marriedgp />} />
            <Route path="/marriedsurety" element={<MarriedSurety />} />

            {/* General  */}
            <Route path="/changepass" element={<ChangePass />} />
            <Route path="/profile" element={<Profile />} />

            {/* planner  */}
            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="/withdrawrow" element={<WithdrawRow />} />
            <Route path="/withdrawrowdetail/:withdrawId" element={<WithdrawRowDetail />} />

            <Route path="/disbursenew" element={<DisburseNew />} />
            <Route path="/disburseapplication/:loanId" element={<DisburseApplication />} />
            <Route path="/withdrawtab/:customerId" element={<WithdrawTabs />} />
            <Route path="/evaluatorchecking/:withdrawId" element={<EvaluatorChecking />} />
            <Route path="/auditorchecking/:withdrawId" element={<AuditorChecking />} />
            <Route path="/managerchecking/:withdrawId" element={<ManagerChecking />} />
            <Route path="/financewithdrawchecking/:withdrawId" element={<FinanceWithdrawChecking />} />

            <Route path="/dfinancechecking/:disburseId" element={<DisburseFinanceChecking />} />

            {/* Reports  */}
            <Route path="/reports/:customerId/:loanId" element={<Reports />} />

            {/* new way of report  */}
            <Route path="/prohibitions/:customerId/:loanId" element={<Prohibitions />} />
            <Route path="/contracts/:customerId/:loanId" element={<Contracts />} />

            <Route path="/tgprohibitions/:customerId/:loanId" element={<TgProhibitions />} />
            <Route path="/tgcontracts/:customerId/:loanId" element={<TgContracts />} />

            <Route path="/requestformdoc/:customerId/:loanId" element={<RequestFormDoc />} />
            <Route path="/rptwithdraw/:withdrawId" element={<RptWithdraw />} />
            <Route path="/rptdisbursement/:disburseId" element={<RptDisbursement />} />

            {/* Reports Management */}

            <Route path="/rpthistoryloan" element={<RptHistoryLoan />} />
            <Route path="/rptplanloan" element={<RptPlansLoan />} />
            <Route path="/rpthistorywithdraw" element={<RptHistoryWithdraw />} />
            <Route path="/rptplanwithdraw" element={<RptPlanWithdraw />} />
            <Route path="/rpthistorydisburse" element={<RptHistoryDisburse />} />
            <Route path="/rptplandisburse" element={<RptPlanDisburse />} />
            <Route path="/ratioscreditanalysis" element={<RatiosCreditAnalysis />} />



            {/* Helps  */}
            <Route path="/helpofficer" element={<HelpOfficer />} />
            <Route path="/helpfinance" element={<FinanceHelp />} />
            <Route path="/helpteamleader" element={<TeamLeaderHelp />} />
            <Route path="/helpplanner" element={<PlannerHelp />} />

            {/* finance  */}
            <Route path="/neard" element={<NearDisburseDate />} />
            <Route path="/nearw" element={<NearWithdrawDate />} />

            {/* Finished Loans  */}
            <Route path="/teamleaderfinishedloans" element={<TeamLeaderFinishedLoans />} />
            <Route path="/withdrawtest" element={<WithdrawTest />} />

            {/* Adresses cascaded  */}
            <Route path="/regions" element={<Regions />} />
            <Route path="/zones" element={<Zones />} />
            <Route path="/woredas" element={<Woredas />} />

            {/* Bank Transfers  */}
            <Route path="/newtransfers" element={<NewTransfers />} />
            <Route path="/syncdata" element={<SyncData />} />
            <Route path="/creditanalysis/:customerId/:loanId" element={<CreditAnalysis />} />

            {/* Self Service  */}
            <Route path="/loanapplications" element={<LoanApplications />} />
            <Route path="/loanapplicationfollowup/:loanId" element={<LoanApplicationFollowup />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
