import React from "react";
import { Text, View } from "@react-pdf/renderer";
import ReportStyle from '../Styles/ReportStyle';


function KeyValue(props) {

    return (
        <View style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: props.bgcolor,
            paddingBottom: 1,
            paddingLeft: 1,
            justifyContent: 'space-between',
            fontSize: 8,
        }}>
            <Text style={ReportStyle.textCreditRatio}> {props.thekey} </Text>
            <Text style={ReportStyle.textCreditRatio}> {props.value} </Text>
        </View>
    )
}

export default KeyValue