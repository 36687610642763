import React, { useState, useEffect } from 'react';
import { Accordion, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance';
import RatiosCreditAnalysis from '../Reports/RatiosCreditAnalysis';
import { PulseLoader } from 'react-spinners';


function CreditAnalysis() {
  const { serverIP } = useSelector(store => store.allsettings);
  const [isLoading, setIsLoading] = useState(false);

  const { customerId, loanId } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loan, setLoan] = useState([]);
  const [committee, setCommittee] = useState([]);
  const [creditRatioData, setCreditRatioData] = useState([]);
  const [conditionData, setConditionData] = useState([]);

  useEffect(() => {
    getRelevantData();
  }, []);


  // Get today's date
  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  if (isLoading) {
    return (
      <div className="h-28 flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: "#FFA500" }}>Loading ...</div>
        </div>
      </div>
    );
  }


  const getRelevantData = async () => {
    setIsLoading(true);
    try {
      const credratio = await axiosInstance.get(`${serverIP}/loanfinanceitems/loanfinanceitemssloanid/${loanId}`)
      setCreditRatioData(credratio.data);
      const cust = await axiosInstance.get(`${serverIP}/customer/customers/${customerId}`)
      let cn_data = cust.data?.isMarried ? cust.data?.marriedgeneralfiles : cust.data?.singlegeneralfiles
      console.log("cn_data")
      console.log(cn_data)
      setConditionData(cn_data);

      setCustomer(cust.data);

      const ln = await axiosInstance.get(`${serverIP}/loan/loans/${customerId}`)
      setLoan(ln.data);


      const commite = await axiosInstance.get(`${serverIP}/loancommittee/active`)
      setCommittee(commite.data);
    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  }


  return (
    <RatiosCreditAnalysis customer={customer} loan={loan} committee={committee} creditRatioData={creditRatioData} formattedDate={formattedDate} conditionData={conditionData} />
  )
}

export default CreditAnalysis


